<template>
  <main class="no-main">
    <section class="section--single-post ps-page--business">
      <div class="ps-breadcrumb">
        <div class="container-fluid-sy">
          <ul class="ps-breadcrumb__list">
            <li class="active">
              <router-link :to="{ name: 'home' }">Home</router-link>
            </li>
            <li class="active">
              <router-link :to="{ name: 'dashboard' }">Myaccount</router-link>
            </li>
            <li><a href="javascript:void(0);">Billing Address</a></li>
          </ul>
        </div> 
      </div>

      <div class="container">
        <div id="address-index">
          <div class="row mb-4 mt-4">
            <div class="col-12 d-flex justify-content-between">
              <h4 class="billing-address">Sender Address</h4>
              {{}}
              <router-link
                class="btn btn-success btn-lg"
                :to="{ name: 'address-create' }"
                v-if="addresses.length == 0"
              >
              Create Address
              </router-link
              >
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div
                class="col-lg-6 mb-4"
                v-for="(address, index) in addresses"
                :key="index"
              >
                <div class="card card-margin">
                  <div class="card-body col-12">
                    <div class="p-0">
                      <div class="row">
                        <div class="col-4">
                          <div><strong> Name</strong></div>
                          <div><strong>Address</strong></div>
                          <div><strong>City</strong></div>
                          <div><strong>Country</strong></div>
                          <div><strong>Phone</strong></div>
                          
                        </div>
                        <div>
                          <div class="font-bold set_dot">:</div>
                          <div class="font-bold set_dot">:</div>
                          <div class="font-bold set_dot">:</div>
                          <div class="font-bold set_dot">:</div>
                          <div class="font-bold set_dot">:</div>
                        </div>
                        <div class="col-7">
                          <div> {{ address.first_name }} {{ address.last_name }}</div>
                          <div>{{ address.address1[0] }}</div>
                          <div>{{ address.city }} - {{ address.postcode }}</div>
                          <div>{{ address.country_name }}</div>
                          <div>{{address.email }}<span v-if="address.email"> / </span>{{ address.phonecode + address.phone }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row pagination justify-content-end mr-1 mt-3">
                      <router-link
                        :to="{
                          name: 'address-edit',
                          params: { id: address.id },
                        }"
                        type="button"
                        class="btn btn-theme mr-1"
                      ><i class="icon-pen"></i> Edit
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

<!-------------------------------------------------------------------------------------------------------------------->
        <div id="address-index">
          <div class="row mb-4 mt-4">
            <div class="col-12 d-flex justify-content-between">
              <h4 class="billing-address">Receiver Address</h4>              
            </div>
          </div>
          <div class="container" v-if="receiveraddresses">
            <div class="row">
              <div class="col-lg-4 mb-4" v-for="(receiver, index) in receiveraddresses" :key="index">
                <div class="card card-margin">
                  <div class="card-body col-12">
                    <div class="p-0">
                      <div class="row">
                        <div class="col-4">
                          <div><strong> Name</strong></div>
                          <div><strong>Address</strong></div>
                          <div><strong>City</strong></div>
                          <div><strong>Country</strong></div>
                          <div><strong>Phone</strong></div>                          
                        </div>
                        <div>
                          <div class="font-bold set_dot">:</div>
                          <div class="font-bold set_dot">:</div>
                          <div class="font-bold set_dot">:</div>
                          <div class="font-bold set_dot">:</div>
                          <div class="font-bold set_dot">:</div>
                        </div>
                        <div class="col-7">
                          <div> {{ receiver.first_name }} {{ receiver.last_name }}</div>
                          <div>{{ receiver.address1[0] }}</div>
                          <div>{{ receiver.city }} - {{ receiver.postcode }}</div>
                          <div>{{ receiver.country_name }}</div>
                          <div>{{receiver.email }}<span v-if="receiver.email"> / </span>{{ receiver.phone }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container text-center" v-else>
          Receiver address not found
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      cheack_address:"",
      AddressId: null,
      isHidden: true,
      visibility: false,
      code: null,
      AddressForm: {
        address1: ["address1"],
        country: "IN",
        state: "",
        city: "Vavuniya",
        postcode: "248002",
        phone: "0123456798",
      },
    };
  },

  beforeMount() {},

  mounted() {
    this.fetchAddresses();
    this.fetchRecevierAddresses();
    
  },

  computed: {
    ...mapGetters("address", ["addresses","receiveraddresses"]),
  },

  methods: {
    ...mapActions("address", ["fetchAddresses","fetchRecevierAddresses", "deleteAddress"]),    
  },
};
</script>

<style lang="scss" scoped>
.save-btn {
  width: 70px;
  height: 35px;
}
.card-body .set_dot{
  margin-left: -10px;
  padding: 0;
}
</style>
